.loading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 4rem;
}

.loading .loading-text {
  font-size: 0.4rem;
  text-align: center;
  color: #474F66;
}