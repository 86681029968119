#root {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f9;
  overflow: hidden;
}

#root .am-navbar {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 1.2rem;
  z-index: 100;
}

#root .am-navbar-light {
  color: #474f66 !important;
}